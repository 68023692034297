import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout/index.vue'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/404',
      component: () => import('@/views/error-page/404.vue'),
      meta: { title: '页面不存在', keepAlive: true },
      hidden: true
    },
    {
      path: '/login',
      component: () => import('@/views/login/index'),
      meta: { title: '登录' },
      hidden: true
    },
    {
      path: '/',
      component: Layout,
      redirect: '/search',
      children: [
        {
          path: 'search',
          component: () => import('@/views/search/index.vue'),
          name: 'SearchPage',
          meta: { title: '搜索', keepAlive: true }
        },
        {
          path: 'profile',
          component: () => import('@/views/my/index.vue'),
          name: 'my',
          meta: { title: '我的', keepAlive: true }
        },
        {
          path: 'work',
          component: () => import('@/views/work/index.vue'),
          name: 'WorkPage',
          meta: { title: '填报', keepAlive: true }
        },
        {
          path: 'record',
          component: () => import('@/views/worklist/index.vue'),
          name: 'worklist',
          meta: { title: '填报记录', keepAlive: true }
        },
        {
          path: 'record/:id',
          component: () => import('@/views/worklist/detail.vue'),
          name: 'workdetail',
          meta: { title: '填报详情', keepAlive: true }
        }
      ]
    },
    { path: '/:catchAll(.*)', redirect: '/404', hidden: true }
  ]
})

export default router