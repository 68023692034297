import axios from 'axios'
import router from '@/router'
import { getToken, setToken, getRefToken, setRefToken,removeToken } from '@/utils/auth'
import { showFailToast } from 'vant';
// create an axios instance
const service = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['accessToken'] = getToken()
    }
    if (getRefToken()){
      config.headers['refreshToken'] = getRefToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    if (response.headers.accesstoken) {
      setToken(response.headers.accesstoken)
    }
    if (response.headers.refreshtoken) {
      setRefToken(response.headers.refreshtoken)
    }
    const res = response.data
    return res
  },
  error => {
    
    if(error.response.status == 401){
      showFailToast('请重新登录');
      removeToken()
      router.push(`/login`)
    }
    else if(error.response.status==500){
      showFailToast(error.response.data.message);
    }

    return Promise.reject(error)
  }
)

export default service
