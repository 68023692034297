import mountComponent from '@/utils/mount';  
import ModalComponent from './index.vue';  
  
export function showChangePassword() {  
    const modal = mountComponent(ModalComponent);  
    modal.emitter.on('close', () => {  
        modal.unmount();
    });  

    modal.open(); // 调用组件的 open 方法来显示弹出框 
}  

//


