import { createStore } from 'vuex'
import getters from './getters'

const modulesFiles = import.meta.glob('./modules/*.js', { eager: true });
const allModules = Object.keys(modulesFiles).map((key) => {
  const module = modulesFiles[key];
  const name = key.replace(/(\.\/modules\/|\.js)/g, '');
  return [name, module.default];
})

const modules = Object.fromEntries(allModules);

const store = createStore({
  modules,
  getters
})

export default store