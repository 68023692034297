import { createApp } from 'vue';  
  
function mountComponent(RootComponent, props = {}) {  
  const app = createApp(RootComponent);  
  const root = document.createElement("div");  
  document.body.appendChild(root);  
  const instance = app.mount(root);  
  
  // 如果RootComponent有open和close方法，则将它们暴露出来  
  if (instance.open && typeof instance.open === 'function') {  
    props.open = instance.open;  
  }  
  if (instance.close && typeof instance.close === 'function') {  
    props.close = instance.close;  
  }  
  props.emitter = instance.emitter
  
  return {  
    instance,  
    ...props,  
    unmount() {  
      app.unmount();  
      document.body.removeChild(root);  
    },  
  };  
}  
  
export default mountComponent;