import { login, logout, getInfo } from '@/api/user'
import {  removeToken } from '@/utils/auth'


const state = {
  id: 0,
  name: '',
  isChange:0,
  mobile:'',
  gangyin:'',
  roles: [],
}

const mutations = {
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_MOBILE: (state, mobile) => {
    state.mobile = mobile
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ISCHANGE: (state, isChange) => {
    state.isChange = isChange
  },
  SET_GANGYIN:(state, gangyin) => {
    state.gangyin = gangyin
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { mobile,gangyin,password } = userInfo
    return new Promise((resolve, reject) => {
      login({ mobile: mobile.trim(),gangyin:gangyin,password:password}).then(response => {
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },


  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { id, roles, name,mobile, gangyin,isChange } = data

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }
        commit('SET_GANGYIN',gangyin)
        commit('SET_ID', id)
        commit('SET_ROLES', roles)
        commit('SET_ISCHANGE', isChange)
        commit('SET_MOBILE', mobile)
        commit('SET_NAME', name)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
   
        commit('SET_id', 0)
        commit('SET_NAME', '')
        commit('SET_MOBILE', '')
        commit('SET_ISCHANGE', 0)
        commit('SET_ROLES', [])
        commit('SET_GANGYIN', '')
        removeToken()
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

 
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
