import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import store from './store'
import "virtual:svg-icons-register"
import SvgIcon from './components/SvgIcon/index.vue'
import './assets/main.css'
import 'vant/lib/index.css'
import './permission'
import * as filters from './filters'

const app = createApp(App)

app.component('svg-icon', SvgIcon)

app.use(Vant)
app.use(router)
app.use(store)

// register global utility filters
Object.keys(filters).forEach(key => {
  app.config.globalProperties.$filters = {
    ...app.config.globalProperties.$filters,
    [key]: filters[key]
  }
})

app.mount('#app')