import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/my/info',
    method: 'get'
  })
}
export function getZiges(){
  return request({
    url: '/my/zglist.html',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

export function getList(data){
  return request({
    url: 'user.html',
    method: 'get',
    params:data
  })
}

export function getUserInfo(id){
  return request({
    url: 'user/'+id+'.html',
    method: 'get'
  })
}

export function createUser(data){
  return request({
    url:"user.html",
    method:"POST",
    data  
  })
}

export function updateUser(id,data){
  return request({
    url: 'user/'+id+'.html',
    method: 'PUT',
    data
  })
}

export function chgpass(id){
  return request({
    url: 'user/'+id+'/chgpass.html',
    method: 'PUT',
  })
}

export function deleteUser(id){
  return request({
    url: 'user/'+id+'.html',
    method: 'DELETE',
  })
}
export function ChangePass(data){
  return request({
    url:"/password",
    method:'put',
    data
  })
}