
const TokenKey = 'accessToken'
const refreshKey = 'refreshToken'

export function getToken() {
  return localStorage.getItem(TokenKey)
}
export function getRefToken(){
  return localStorage.getItem(refreshKey)
}
export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}
export function setRefToken(token){
  return localStorage.setItem(refreshKey, token)
} 
export function removeToken() {
  return localStorage.removeItem(TokenKey) && localStorage.removeItem(refreshKey);
}
