<template>
    <div>
        <div style="width: 100%;height: var(--van-tabbar-height);"></div>
        <van-tabbar v-model="active" :safe-area-inset-bottom="true" @change="onChange">
            <van-tabbar-item replace to="/search" icon="search">搜索</van-tabbar-item>
            <van-tabbar-item replace to="/record" icon="records-o">记录</van-tabbar-item>
            <van-tabbar-item replace to="/profile" icon="user-o">我的</van-tabbar-item>
        </van-tabbar>
    </div>

</template>
<script>
export default {
    name: 'BottomNav',
    data() {
        return {
            active: 0
        }
    },
    watch: {
        '$route'(to, from) {
            if (to.path === '/search') {
                this.active = 0;
            } else if (to.path === '/record' || this.$route.name === 'workdetail') {
                this.active =  1;
            } else if (to.path === '/profile') {
                this.active =  2;
            } else {
                this.active =  3;
            }
        }  
       
    },
    methods: {
        onChange(event) {

        },
    },
    mounted() {
        if (this.$route.path === '/search') {
                this.active = 0;
            } else if (this.$route.path === '/record' || this.$route.name === 'workdetail') {
                this.active =  1;
            } else if (this.$route.path === '/profile') {
                this.active =  2;
            } else {
                this.active =  3;
            }
    }

}
</script>