<template>
    <van-popup v-model:show="show" :close-on-click-overlay="false" @close="onClose" :style="{ padding: '10px 0' }">
        <van-form @submit="onSubmit" label-width="4em">
            <van-cell-group inset>
                <van-field v-model="password" type="password" name="password" label="新密码" placeholder="请设置新密码"
                    :rules="[{ required: true, message: '请填写密码' }]" />
                <van-field v-model="repassword" type="password" name="repassword" label="重复密码" placeholder="请输入重复密码"
                    :rules="[{ required: true, message: '请填写重复密码' }, { validator: checkPasswordsMatch, message: '两次输入的密码不一致' }  ]" />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block :loading="btnloading"  type="primary" native-type="submit">
                    提交
                </van-button>
            </div>
        </van-form>
    </van-popup>
</template>
<script>
import { Field, CellGroup, Button, Form, Popup ,showNotify,showFailToast} from 'vant';
import store from '@/store'
import { ChangePass } from '@/api/user'
import mitt from 'mitt';  
export default {
    components: {  
    'van-field': Field,  
    'van-cell-group': CellGroup,  
    'van-button': Button,  
    'van-form': Form,  
    'van-popup': Popup  
  }  ,
    data() {
        return {
            show: false,
            btnloading: false,
            password: '',
            repassword: '',
            emitter:mitt()
        };

    },
    methods: {
        open() { 
            this.show = true;
        },
        close() { 
            this.show = false;
        },
        onClose() { 
            this.password='';
            this.repassword='';
            this.emitter.emit('close');  
        },
        onSubmit(values) {
            this.btnloading=false;
            let _this=this;
            ChangePass(values).then(res => {
                showNotify({ type: 'success', message: '密码修改成功' });
                 store.commit('user/SET_ISCHANGE', 1);
                _this.close();
            })
        },
        checkPasswordsMatch(val) {  
            return this.password === val;  
        }  
    }
}
</script>
