<template>
  <div class="navbar">
    <van-nav-bar v-if="isback" :title="title" left-arrow  left-text="返回" @click-left="onClickLeft" :fixed="true"/>
    <van-nav-bar :title="title" :fixed="true" v-else/>
  </div>
</template>
<script>
export default {
    name:'HeaderNav',
    computed:{
        title(){
            return this.$route.meta.title
        },
        isback(){
         if(this.$route.name=='WorkPage' || this.$route.name=='workdetail'){
          return true
         }else{
          return false
         }
        }
    },
    methods:{
      onClickLeft() {
        if( this.$route.name=='workdetail'){
        this.$router.go(-1);
        }else{
          this.$router.push({name:'SearchPage'});
        }
      },
    }

}
</script>
<style scoped lang="scss">
  .navbar{ 
    &::after{
      height: var(--van-nav-bar-height);
      content: " ";
      display: block;
    }
  }
</style>