<template>
    <div class="app-wrapper">
        <header-nav/>
        <app-main />
        <bottom-nav/>
      </div>
    
  </template>
  
  <script>
 
  import { AppMain,HeaderNav,BottomNav } from './components'
  
  export default {
    name: 'Layout',
    components: {
      AppMain,
      HeaderNav,
      BottomNav
    },
    computed: {
     
     
    },
    methods: {
    
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>
  